import { lazy } from "react";

const ParentMain = lazy(() => import("../Container"));
const AdvertiserPage = lazy(() => import("../components/AdvertiserPage"));
const BlogDetailComponent = lazy(() => import("../components/AreaOfStudies/BlogDetailComponent"));
const PrivacyPolicy = lazy(() => import("../components/AreaOfStudies/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("../components/AreaOfStudies/TermsOfUse"));
const FormIndex = lazy(() => import("../components/LeadFormSteps/FormIndex"));


export const areaRoutes = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: ParentMain
    },
    // {
    //     name: "Business",
    //     path: "/business",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Business",
    //     path: "/business/:id",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Computers & IT",
    //     path: "/computers&it",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Computers & IT",
    //     path: "/computers&it/:id1",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Criminal Justice",
    //     path: "/criminaljustice",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Criminal Justice",
    //     path: "/criminaljustice/:id2",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Education & Teaching",
    //     path: "/education&teaching",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Education & Teaching",
    //     path: "/education&teaching/:id3",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Healthcare",
    //     path: "/healthcare",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Healthcare",
    //     path: "/healthcare/:id4",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Psychology",
    //     path: "/psychology",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Psychology",
    //     path: "/psychology/:id5",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Nursing",
    //     path: "/nursing",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Nursing",
    //     path: "/nursing/:id6",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Vocational Training",
    //     path: "/vocationaltraining",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Vocational Training",
    //     path: "/vocationaltraining/:id7",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Liberal Arts",
    //     path: "/liberalarts",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "Liberal Arts",
    //     path: "/liberalarts/:id8",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "General",
    //     path: "/undecided",
    //     exact: true,
    //     component: ParentMain
    // },
    // {
    //     name: "General",
    //     path: "/undecided/:id9",
    //     exact: true,
    //     component: ParentMain
    // },
    {
        name: "Blog",
        path: "/college-review",
        exact: true,
        component: BlogDetailComponent
    },
    {
        name: "Terms Of Use",
        path: "/terms-of-use",
        exact: true,
        component: TermsOfUse
    },
    {
        name: "PrivacyPolicy",
        path: "/privacy-policy",
        exact: true,
        component: PrivacyPolicy
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    // {
    //     name: "LeadForm",
    //     path: "/get-information/:universityName?/:mProgramName?",
    //     exact: true,
    //     component: AdvertiserPage
    // },

    {
        name: "Business",
        path: "/business/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Criminal Justice",
        path: "/criminal-justice/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Psychology",
        path: "/psychology/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Computers & IT",
        path: "/computers-&-it/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Healthcare",
        path: "/healthcare/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Education & Teaching",
        path: "/education-&-teaching/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Vocational Training",
        path: "/vocational-training/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Liberal Arts",
        path: "/liberal-arts/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "General",
        path: "/general/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Nursing",
        path: "/nursing/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Media & Communications",
        path: "/media-&-communications/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Media & Communications",
        path: "/media-&-communications/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Visual Arts & Graphic Design",
        path: "/visual-arts-&-graphic-design/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Religious Studies",
        path: "/religious-studies/:id?",
        exact: true,
        component: ParentMain
    },
    {
        name: "Home Page",
        path: "*",
        exact: true,
        component: ParentMain
    },
]